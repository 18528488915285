import React, { useState, useEffect } from "react";
import {TextField,MenuItem,Button,Modal,Box,Typography,} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import contactusBg from "../../../../images/contactusBg.png";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import officemap from "../../../../images/office_map.png";
import RecommendIcon from "@mui/icons-material/Recommend";
import axios from "axios";
 
export default function ContactUs() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
 
  const [open, setOpen] = useState(false); // State to control the modal
const API_URL = process.env.REACT_APP_API_URL;
console.log("API URL:", API_URL);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
 
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("66")
    try {
      await axios.post(`${API_URL}/api/contact/saveContact`, formData);
      console.log("User data saved successfully!");
      setOpen(true);
     
      setTimeout(() => {
        setOpen(false);
      }, 10000);
 
      const phoneNumber = "917798283335";
      const message = encodeURIComponent(
        "Hello, I'm interested in learning more about your services!"
      );
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappURL, "_blank");
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };
 
  const handleClose = () => {
    setOpen(false); // Close the modal
  };
 
  // Validation function to check if all fields are filled
  const isFormValid = () => {
    return (
      formData.firstName.trim() !== "" &&
      formData.lastName.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.phone.trim() !== "" &&
      formData.subject.trim() !== "" &&
      formData.message.trim() !== ""
    );
  };
 
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Background Image Section */}
      <div
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(70, 101, 145, 0.77) 0%, rgba(6, 25, 55, 0.77) 100%), url(${contactusBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textAlign: "center",
        }}
      >
        <div>
          <h2 style={{fontSize: "48px", fontWeight: 600 }}>Contact us</h2>
          <p style={{ color: "#C9C9C9", fontWeight: 400, fontSize: "12px" }}>
            Feel free to reach out to us for any queries or support.
            <br />
            Our team is ready to assist students and parents with course
            details, enrollment, and more.
          </p>
        </div>
      </div>
 
      {/* Contact Form Section with Grid */}
      <div
        style={{
          position: "relative",
          width: "80%",
          display:'flex',
          margin: "-100px auto 0",
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Grid container spacing={4}>
          <Grid
            size={matches ? 12 : 4}
            style={{
              backgroundColor: "#0056D2",
              color: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h3 style={{ fontSize: "22px", fontWeight: "bold" }}>
              Get in touch
            </h3>
            <p
              style={{
                fontFamily: "Poppins",
                marginBottom: "20px",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "130%",
                letterSpacing: "0%",
              }}
            >
              We’d love to hear from you! Whether you have a question, feedback,
              or need assistance, we’re here to help.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Call Us:</strong>
            </p>
            <p style={{ marginBottom: "10px" }}>
              <AddIcCallIcon /> +91-77982 83335
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Email Us:</strong>
            </p>
            <p style={{ marginBottom: "10px" }}>
    <EmailIcon />
    <a href="mailto:Connect@simplifyingskills.com" style={{ color: "white", textDecoration: "none", marginLeft: "5px" }}>
        Connect@simplifyingskills.com
    </a>
</p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Head Office:</strong>
            </p>
            <p style={{ marginBottom: "10px" }}>
              <LocationOnIcon /> M3M 65th Avenue, Sector 65, Gurugram
            </p>
 
            {/* Social Media Section */}
            <h4 style={{ marginTop: "20px" }}>Follow our social media</h4>
            <div style={{ display: "flex", gap: "10px" }}>
              <a
                href="https://www.instagram.com/simplifying_skills/"
                style={{ color: "white" }}
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/SimplifyingSkillsConsultants"
                style={{ color: "white" }}
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.youtube.com/@simplifyingskills"
                style={{ color: "white" }}
              >
                <YouTubeIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/simplifying-skills-consultants/"
                style={{ color: "white" }}
              >
                <LinkedInIcon />
              </a>
            </div>
          </Grid>
          <Grid size={matches ? 12 : 8} style={{ padding: "20px" }}>
            <h3
              style={{
                marginBottom: "20px",
                color: "#154BB1",
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Send us a message
            </h3>
            <form>
              <Grid container spacing={2}>
                <Grid size={matches ? 12 : 6}>
                  <TextField
                    required
                    name="firstName"
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    onChange={handleChange}
                    sx={{ backgroundColor: "#f0f2ff", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid size={matches ? 12 : 6}>
                  <TextField
                    required
                    name="lastName"
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    onChange={handleChange}
                    sx={{ backgroundColor: "#f0f2ff", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid size={matches ? 12 : 6}>
                  <TextField
                    required
                    name="email"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    sx={{ backgroundColor: "#f0f2ff", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid size={matches ? 12 : 6}>
                  <TextField
                    required
                    name="phone"
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    onChange={handleChange}
                    sx={{ backgroundColor: "#f0f2ff", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Select Subject"
                    variant="outlined"
                    onChange={handleChange}
                    name="subject"
                    sx={{ backgroundColor: "#f0f2ff", borderRadius: "5px" }}
                  >
                    <MenuItem value="Course Details">Course Details</MenuItem>
                    <MenuItem value="Enrollment">Enrollment</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    name="message"
                    label="Write your message..."
                    variant="standard"
                    onChange={handleChange}
                    sx={{ borderRadius: "5px" }}
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    style={{ backgroundColor: "#0056D2", color: "white" }}
                    disabled={!isFormValid()} // Disable button if form is not valid
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
 
      {/* Plain White Section */}
      <div style={{ height: "10vh", backgroundColor: "white" }}></div>
      <div style={{ position: "relative", width: "100%", height: "70vh" }}>
        {/* Office Map Image */}
        <img
          src={officemap}
          alt="Office Map"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
 
        {/* Location Icon Overlay */}
        <LocationOnIcon
          style={{
            position: "absolute",
            top: matches ? "34%" : "27%",
            left: matches ? "47%" : "48.5%",
            transform: "translate(-50%, -50%)",
            fontSize: "40px",
            color: "red",
          }}
        />
      </div>
 
      {/* Success Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <Box
    sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "400px",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
        borderRadius: "12px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
       
    }}
>
    <RecommendIcon sx={{ fontSize: { xs: 60, sm: 80, md: 100 }, color: "#0056D2" }} />  
    <Typography
        id="modal-modal-description"
        sx={{ mt: 2, fontSize: { xs: "14px", sm: "16px" }, fontWeight: 500 }}
    >
        Thanks for contacting us!
    </Typography>
    <Button
        variant="contained"
        onClick={handleClose}
        sx={{
            mt: 2,
            backgroundColor: "#0056D2",
            color: "white",
            width: "100%",
            maxWidth: "200px"
        }}
    >
        Close
    </Button>
</Box>
 
      </Modal>
    </div>
  );
}