import { Card, CardContent, Typography, Grid } from "@mui/material";
import { motion, useInView } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Bar_on from "../../../images/ria.png"
// import { useTypewriter } from "./Textanimation"
import half_orange from "../../../images/half_orange.png"
import { useRef } from "react";
import halfgreen_Star from "../../../images/halfgreen_Star.png"
import { Paper } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

export default function Core() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
            window.scrollTo(0, 0); // Scroll to the top of the page
          }, []);
    

    // const ref1 = useRef(null);
    // const ref2 = useRef(null);
 

    
    // const isInView1 = useInView(ref1, { once: true });
    // const isInView2 = useInView(ref2, { once: true });
    
  
    // const text1 = useTypewriter(
    //     "  The Bar-On Model of Emotional Intelligence (EQ-i) is a pioneering framework for measuring Emotional Intelligence (EI). The model focuses on key areas like self-awareness, self-management, social awareness, and social skills, influencing education, leadership, and personal development.", 
    //     30,
    //     isInView1,
    //     200 
    // );

    // const text2 = useTypewriter(
    //     " The Bar-On Model of Emotional Intelligence (EQ-i) was developed by Dr. Reuven Bar-On in the 1980s as part of his doctoral research to define and measure Emotional Intelligence (EI) scientifically. It led to the creation of the Emotional Quotient Inventory (EQ-i), the first validated EI assessment. Over time, the model evolved and became widely used in education, leadership, mental health, and personal development, shaping the understanding of EI and its applications.", 
    //     30,
    //     isInView2,
    //     300 
    // );

    
    return (
        <div
            style={{
                height: "auto",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
                fontFamily: 'Nunito'
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    alignItems: "center",
                    justifyContent: 'center',
                    width: matches ? "95%" : "90%",
                    gap: matches ? "20px" : "80px",
                    margin: "0 auto",
                }}
            >
                {/* Left Side */}

                <div style={{ textAlign: matches ? "center" : "left" }}>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: matches ? "center" : "flex-start",
                        }}
                    >

                        <div>
                            <div>
                                <motion.div
                                    initial={{ opacity: 0, y: -20, scale: 0.8 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    transition={{ duration: 0.8, ease: "easeOut" }}
                                    whileHover={{ scale: 1.05 }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Nunito",
                                            fontWeight: 700,
                                            fontSize: matches ? "36px" : "100px",
                                            color: "#0072FF",
                                        }}
                                    >
                                        Core
                                    </Typography>
                                </motion.div>
                            </div>
                            {/* Animated Curve */}
                            <div>
                                <motion.svg
                                    width={matches ? 140 : 250}
                                    height={matches ? 8 : 25}
                                    viewBox="0 0 480 30"
                                    strokeLinecap="round"
                                    style={{
                                        display: "block",
                                        marginTop: matches ? "-10px" : "-35px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                >
                                    <motion.path
                                        d="M10,15 Q275,-10 470,15"
                                        stroke="#E66A35"
                                        strokeWidth={matches ? "2.5" : "8"}
                                        fill="transparent"
                                        initial={{ pathLength: 0, opacity: 0 }}
                                        animate={{ pathLength: 1, opacity: 1 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}
                                    />
                                </motion.svg>
                            </div>
                        </div>
                    </div>



                    {/* Model */}
                    <Typography
                        style={{
                            fontFamily: "Nunito",
                            fontWeight: 700,
                            fontSize: matches ? "28px" : "80px",
                            color: "#151617",
                        }}
                    >
                        Model
                    </Typography>
                </div>

                {/* Right Side */}
                <div style={{ width: matches ? "90%" : "500px" }}>
                    <img
                        src={Bar_on}
                        alt="OCEAN Model"
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                        }}
                    />
                </div>
            </div>
            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
                 
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                {/* First Paragraph */}
                <Typography
                    // ref={ref1}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                    The CORE Model is a key component of the Adversity Quotient (AQ) framework that evaluates how individuals handle challenges, setbacks, and adversity. It helps assess resilience and adaptability, making it useful in personal and professional development.
                </Typography>
                </motion.div>
            </div>
            <div style={{ marginTop: matches ? '5%' : '3%', marginBottom: matches ? '5%' : '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "36px" : "68px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                    Origin and Development
                </Typography>
            </div>

            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                <Typography
                    // ref={ref2}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                 Developed by Dr. Paul G. Stoltz in the 1990s, the CORE Model is part of the AQ framework, designed to measure and improve one's ability to navigate adversity. It has been widely applied in education, corporate training, leadership development, and personal growth to enhance persistence and problem-solving skills.
                </Typography>
                </motion.div>
            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", marginBottom: matches ? "5%" : "3%" }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "18px" : "32px",
                        lineHeight: "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        color: "#3479FF",
                    }}
                >
                    Key Components of the CORE Model 
                </Typography>
            </div>

            <div>
                <Grid container spacing={matches ? 2 : 4} justifyContent="center" padding={matches ? 2 : 3}>

                    {/* First Row */}
                    <Grid container item spacing={matches ? 2 : 5} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 1, title: "Control", text: "The extent to which an individual believes they can influence a situation.  ", bg: "#E9D5FF" },
                            { id: 2, title: "Ownership", text: "The willingness to take responsibility for outcomes rather than blaming external factors.  ", bg: "#D2A2F2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Second Row */}
                    <Grid container item spacing={matches ? 2 : 4} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 3, title: "Reach", text: "The ability to limit the negative impact of adversity to a specific area rather than letting it affect all aspects of life.  ", bg: "#FFF07B" },
                            { id: 4, title: "Endurance", text: "The perception of how long adversity will last and the ability to persist through challenges.  ", bg: "#6DBFF2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", }}>
                <div style={{ display: 'flex' }} >
                    

                    <div style={{  width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", padding: "20px 0" }}>
    <div
        style={{
            width: matches ? "100%" : "100%",
            paddingLeft: matches ? "0px" : "50px",
            textAlign: "center",
            display:'flex',
            justifyContent:'center',
        }}
    >
        <Typography
            sx={{
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: matches ? "36px" : "68px",
                lineHeight: "110%",
                letterSpacing: "0px",
                textAlign: "center",
                color: "#3479FF",
            }}
        >
            Where is it Used
        </Typography>
    </div>

    {/* Image container - pushes image to the right */}
    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <img 
            src={halfgreen_Star} 
            alt="Star Icon"
            style={{
                display: "block",
                width: "10%",
                position: "absolute",
            }} 
        />
    </div>
</div>

                </div>
                <div
                    style={{
                        justifyContent:'center',
                        width: matches ? "95%" : "70%",
                        margin: "1.5% auto",
                        textAlign: 'left',
                        
                    }}
                >
                    <ul
                        style={{
                            fontFamily: "Nunito",
                            fontSize: matches ? "14px" : "18px",
                            lineHeight: "150%",
                            paddingLeft: "20px",
                            marginTop: "15px",
                        }}
                    >
                        <li>
                            <strong>Education :</strong>  Helps students build mental resilience, handle academic pressure, and develop problem-solving skills. 
                        </li>
                        <li>
                            <strong>Corporate & Leadership Development  :</strong> Assists professionals in managing workplace challenges and enhancing leadership effectiveness.
                        </li>
                        <li>
                            <strong>Sports & Performance Coaching :</strong> Used to strengthen athletes’ mental endurance and adaptability. 
                        </li>
                        <li>
                            <strong>Psychological & Personal Growth Programs :</strong> Helps individuals overcome setbacks and develop a positive mindset. 
                        </li>
                    </ul>
                </div>

            </div>
            <div style={{ marginTop:  '3%', marginBottom:  '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "26px" : "53px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                   CORE Model in SkillSphere & Child Development                 </Typography>
                <div style={{paddingLeft:'5%',width:'90%'}}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "23px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                    The RIASEC Model plays a crucial role in SkillSphere assessments by helping children make informed career choices based on their strengths.  
                </Typography>
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        maxWidth: "100%",
                        padding: matches ? 1 : 3,
                        display: "flex",
                        flexWrap: matches ? "wrap" : "nowrap",
                        overflowX: matches ? "unset" : "auto"
                    }}
                >

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#9B76D1",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '14px' : '18px', fontWeight: 400 }}>
                                Building Confidence & Adaptability  
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Helps children develop a growth mindset, handle failures positively, and stay motivated despite setbacks.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F2652A",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '14px' : '18px', fontWeight: 400 }}>
                                Problem-Solving & Decision-Making  
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Develops critical thinking and problem-solving skills, helping children navigate challenges by breaking them into manageable parts.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#44AA8B",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column" ,  justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '14px' : '18px', fontWeight: 400 }}>
                                Emotional & Social Strength  
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Encourages self-responsibility and accountability while helping children manage emotions, stress, and develop patience.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F5A751",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '5px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '14px' : '18px', fontWeight: 400 }}>
                                Long-Term Success & Future Readiness  
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Cultivates resilience by turning setbacks into learning opportunities, preparing students for real-world challenges and a strong future.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
            <div style={{ display: 'flex', marginTop: '5%', marginBottom: '5%' }} >
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <img src={half_orange} style={{ display: 'block', width: matches ? '50%' : '80%' }} />
                </div>

                <div style={{ width: '100vw', display: 'flex' }} >
                    <div
                        style={{
                            width: matches ? "80%" : "80%",
                            paddingLeft: matches ? "0px" : "50px",
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Nunito",
                                fontWeight: 700,
                                fontSize: matches ? "20px" : "32px",
                                lineHeight: "120%",
                                color: "#0C0B0B",
                                paddingLeft:'7%'
                            }}
                        >
                            It builds resilience, enhances problem-solving skills, and fosters adaptability, empowering children for lifelong success.
                        </Typography>
                    </div>

                </div>

            </div>



        </div>
    );
}