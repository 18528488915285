import { useState } from "react";
import Sidebar from "./Sidebar";

export default function TestimonialSubmit() {
  const [formData, setFormData] = useState({
    name_of_person: "",
    organization: "",
    review: "",
  });
const API_URL = process.env.REACT_APP_API_URL;
console.log("API URL:", API_URL);
  console.log(API_URL);
  const [message, setMessage] = useState(""); // State for showing success/error message

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear previous message

    try {
      const response = await fetch(`${API_URL}/api/testimonial/submitTestimonial`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Testimonial submitted successfully!");
        setMessage("Testimonial submitted successfully! ✅");
        setFormData({ name_of_person: "", organization: "", review: "" }); // Reset form
      } else {
        console.error(`Error: ${data.message}`);
        setMessage("Failed to submit testimonial. ❌");
      }
    }
    
    catch (error) {
      console.error("Submission Error:", error);
      setMessage("Something went wrong. Please try again later. ❌");
    }
  };

  return (
    <div className="w-screen pt-[2%] px-[1%] h-[100vh] bg-[#f1f2f6] flex flex-row">
      <Sidebar />

      <div className="flex w-full ml-2">
        <form
          className="w-full max-w-6xl bg-white p-6 rounded-lg shadow-md flex items-start gap-6"
          onSubmit={handleSubmit}
        >
          {/* Form Fields on the Left */}
          <div className="flex-1 space-y-4">
            <input
              type="text"
              name="name_of_person"
              placeholder="Name of the Person"
              value={formData.name_of_person}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />

            <input
              type="text"
              name="organization"
              placeholder="Name of the Organization"
              value={formData.organization}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />

            <textarea
              name="review"
              placeholder="Write your review..."
              value={formData.review}
              onChange={handleChange}
              className="w-full p-2 border rounded h-40"
              required
            />
          </div>

          {/* Submit Button on the Right */}
          <div className="flex flex-col items-end">
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white p-2 px-16 rounded shadow-md"
            >
              Submit Testimonials
            </button>

            {/* Message display */}
            {message && (
              <p className={`mt-2 text-sm ${message.includes("✅") ? "text-green-600" : "text-red-600"}`}>
                {message}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
