import React from "react"
import Banner from './Banner';// Banner path to dispaly on screen
import ProductSection from "./ProductSection"; //Ensure Correct Path fot Product.
import AdvantagesSection from './AdvantageSection';// path for advantages
import SkillsSection from './SkillsSection';// import skillsection to fetch details and render on screen
import StoriesSection from './StoriesSection';
import TestimonialsSection from './TestimonialsSection';
import WhatOurStudentSays from "./What_Our_Student_Says";
import Gallery from "./Gallery"
import StudentGallery from "./StudentGallery";
import axios from   "axios";
import { useEffect,useState } from "react";
const Home = function () {

    
    const [data,setData] = useState([]);
    const [loading,setLoading] =useState(true)
    const [error,setError] = useState(null)

    const fetchData = async () => {
        try{
            setLoading(true)
            const response  = await axios.get('https://simplifyingskills.com/api/testimonial/getAllTestimonials')

            setData(response.data)
            setError(null)
        }catch (err) {
            setError(err.message);
            setData(null);
          } finally {
            setLoading(false);
          }
    
    }
    console.log("home",data)
        useEffect(()=>{
            fetchData()
        },[])

    return (
        <>
             <Banner />
             <ProductSection />
       <AdvantagesSection />
          <SkillsSection /> 
            <section id="gallery"> <StudentGallery/> </section>
           <StoriesSection />
           <TestimonialsSection Data={data} /> 
        <Gallery/>   





        </>
    )
}
export default Home;