import { useLocation } from "react-router-dom";
import background from "../../../images/describeStoryBg.png";
import blog4 from "../../../images/Blog 4.jpeg";
import blog5 from "../../../images/Blog 5 Ravan.webp";
import blog6 from "../../../images/Blog 6Avika.jpeg";
import blog7 from "../../../images/Blog 7 Image.jpg";
import common from "../../../images/describe3.png"
import { useEffect } from "react";


export default function DescribeStory() {

      useEffect(() => {
              window.scrollTo(0, 0); // Scroll to the top of the page
            }, []);
  const location = useLocation();
  const data = location.state || {};
  console.log(data?.full)

  const splitIntoParagraphs = (text, wordsPerParagraph = 40) => {
    if (!text) return [];
    const words = text.split(" ");
    const paragraphs = [];
    
    for (let i = 0; i < words.length; i += wordsPerParagraph) {
      paragraphs.push(words.slice(i, i + wordsPerParagraph).join(" "));
    }
  
    return paragraphs;
  };
  

  return (
    <div
      className="w-screen h-auto pt-[5%] justify-between flex flex-col lg:flex-row"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full lg:w-[68%] px-4 lg:pl-[10%] flex flex-col">
        <div>
          <div className="flex">Welcome, Student</div>
          <div className="text-[#bdc3c7]">
            Blog <span className="mx-1">/</span>
            <span>{data.name}</span>
          </div>

          <div className="w-full flex justify-center my-4 md:my-6">
            <div className="relative w-full">
              <img 
                src={data?.img} 
                alt={data?.name || "Story image"} 
                className="w-full h-[200px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] object-cover rounded-xl shadow-md"
              />
            </div>
          </div>
          
          <div className="text-[#E56F8C]">
            EDUCATION
          </div>
          <div className="text-2xl lg:text-4xl font-bold mt-3">
            {data?.name}
          </div>
          
          <div
            className="text-pretty text-[#000000] mt-3 font-light"
            style={{
              fontFamily: "'Poppins', sans-serif",
              color: "#2d3748",
              fontWeight: 300,
              lineHeight: 1.8,
              fontSize: "clamp(1rem, 1.5vw, 1.125rem)",
              textAlign: "justify",
              letterSpacing: "0.01em",
              textShadow: "0.5px 0.5px 1px rgba(0,0,0,0.05)",
              backdropFilter: "blur(2px)",
              border: "1px solid rgba(255,255,255,0.3)",
            }}
          >
            {splitIntoParagraphs(data?.full).map((para, index) => (
              <p key={index}>
                {para}
              </p>
            ))}
          </div>

          <div className="w-full flex justify-center my-4 md:my-6">
            <div className="relative w-full">
              <img 
                src={common} 
                alt={data?.name || "Story image"} 
                className="w-full h-[200px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] object-cover rounded-xl shadow-md"
              />
            </div>
          </div>
          
          <div style={{
            background: 'linear-gradient(135deg, #f8f9fa 0%, #e9f5e9 100%)',
            borderRadius: '16px',
            padding: '24px',
            margin: '24px 0',
            boxShadow: '0 8px 24px rgba(0,72,36,0.1)',
            borderLeft: '6px solid #4CAF50',
            fontFamily: "'Poppins', sans-serif"
          }}>
            <h2 style={{
              color: '#2E7D32',
              fontSize: '1.5rem',
              marginBottom: '24px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2E7D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 8V12" stroke="#2E7D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 16H12.01" stroke="#2E7D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Active Engagement with the Material
            </h2>

            <div style={{
              display: 'grid',
              gap: '16px'
            }}>
              {/* Questioning */}
              <div style={{
                background: 'white',
                padding: '16px',
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}>
                <h3 style={{
                  color: '#4CAF50',
                  marginBottom: '12px',
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }}>Questioning</h3>
                <p style={{
                  color: '#424242',
                  lineHeight: '1.6',
                  margin: 0
                }}>
                  As you learn new material, ask questions to deepen your understanding. Think why certain information is presented in a specific way.
                </p>
              </div>

              {/* Analyzing Information */}
              <div style={{
                background: 'white',
                padding: '16px',
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}>
                <h3 style={{
                  color: '#4CAF50',
                  marginBottom: '12px',
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }}>Analyzing Information</h3>
                <p style={{
                  color: '#424242',
                  lineHeight: '1.6',
                  margin: 0
                }}>
                  Break down complex concepts into smaller parts and examine how they relate to each other. Just like the ants decided and found a solution for their challenge.
                </p>
              </div>

              {/* Reflecting on Bias */}
              <div style={{
                background: 'white',
                padding: '16px',
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}>
                <h3 style={{
                  color: '#4CAF50',
                  marginBottom: '12px',
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }}>Reflecting on Bias</h3>
                <p style={{
                  color: '#424242',
                  lineHeight: '1.6',
                  margin: 0
                }}>
                  Ask yourself whether the material presents multiple perspectives and whether your interpretation of the material might be influenced by your own biases.
                </p>
              </div>

              {/* Applying Concepts */}
              <div style={{
                background: 'white',
                padding: '16px',
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}>
                <h3 style={{
                  color: '#4CAF50',
                  marginBottom: '12px',
                  fontSize: '1.1rem',
                  fontWeight: '500'
                }}>Applying Concepts</h3>
                <p style={{
                  color: '#424242',
                  lineHeight: '1.6',
                  margin: 0
                }}>
                  Use real-world examples or hypothetical scenarios to apply the concepts you're learning.
                </p>
              </div>

              {/* Final Thought */}
              <div style={{
                background: 'rgba(76, 175, 80, 0.1)',
                padding: '16px',
                borderRadius: '12px',
                borderLeft: '4px solid #4CAF50',
                marginTop: '16px'
              }}>
                <p style={{
                  color: '#2E7D32',
                  lineHeight: '1.6',
                  margin: 0,
                  fontWeight: '500',
                  fontStyle: 'italic'
                }}>
                  If critical thinking skills can help so much when developed during learning, have you ever thought: How might embracing a mindset of curiosity and critical thinking shape the way you navigate the world around you?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Latest Articles Section - Hidden on xs, sm, md screens */}
      <div className="hidden lg:block lg:w-[25%] mr-8 flex flex-col mt-[5%] p-3 rounded-xl lg:h-[100vh] bg-white">
        <div className="font-bold">Latest Article</div>

        <div className="flex flex-row w-full h-[18vh] items-center">
          <div>
            <img src={blog4} className="w-[150px] h-[90px] rounded-xl" />
          </div>
          <div className="flex justify-center font-bold text-lg pl-2 align-middle">
            The Miracle of Breaks
          </div>
        </div>

        <div className="flex flex-row w-full h-[18vh] items-center">
          <div>
            <img src={blog5} className="w-[150px] h-[90px] rounded-xl" />
          </div>
          <div className="flex justify-center font-bold text-lg pl-2 align-middle">
            Eco-Friendly Dussehra
          </div>
        </div>

        <div className="flex flex-row w-full h-[18vh] items-center">
          <div>
            <img src={blog6} className="w-[150px] h-[90px] rounded-xl" />
          </div>
          <div className="flex justify-center font-bold text-lg pl-2 align-middle">
            Prioritize and Prevail
          </div>
        </div>

        <div className="flex flex-row w-full h-[18vh] items-center">
          <div>
            <img src={blog7} className="w-[150px] h-[90px] rounded-xl" />
          </div>
          <div className="flex justify-center font-bold text-lg pl-2 align-middle">
            One Chunk at a Time
          </div>
        </div>

        {/* Tags Section */}
        <div className="mt-6">
          <h2 className="font-bold text-lg mb-2">Tags</h2>
          <div className="flex flex-wrap gap-2">
            {[
              'Critical Thinking',
              'Problem-Solving',
              'Learn',
              'Mindset',
              'Growth',
              'Education',
            ].map((tag, index) => (
              <span
                key={index}
                className="bg-blue-500 text-white text-sm font-medium px-3 py-1 rounded-md"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}