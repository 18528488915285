import { Card, CardContent, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import oceanModel from "../../../images/oceanModel.png"
import { useTheme } from "@mui/material/styles";
import Star from "../../../images/Star_1.png"
import orangestar from "../../../images/orange_star.png"
import { Paper } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

export default function OceanModel() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div
      style={{
        height: "auto",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingTop: "20px",
        fontFamily: 'Nunito'
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          alignItems: "center",
          width: matches ? "95%" : "90%",
          gap: matches ? "20px" : "80px",
          margin: "0 auto",
        }}
      >
        {/* Left Side */}

        <div style={{ textAlign: matches ? "center" : "left" }}>
          {/* The + OCEAN */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: matches ? "center" : "flex-start",
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: 700,
                  fontSize: matches ? "28px" : "80px",
                  color: "#151617",
                }}
              >
                The
              </Typography>
            </div>
            <div>
              <div>
                <motion.div
                  initial={{ opacity: 0, y: -20, scale: 0.8 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                  whileHover={{ scale: 1.05 }}
                >
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      fontSize: matches ? "36px" : "100px",
                      color: "#0072FF",
                    }}
                  >
                    OCEAN
                  </Typography>
                </motion.div>
              </div>
              {/* Animated Curve */}
              <div>
                <motion.svg
                  width={matches ? 140 : 400}
                  height={matches ? 8 : 25}
                  viewBox="0 0 480 30"
                  strokeLinecap="round"
                  style={{
                    display: "block",
                    marginTop: matches ? "-10px" : "-35px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <motion.path
                    d="M10,15 Q275,-10 470,15"
                    stroke="#E66A35"
                    strokeWidth={matches ? "2.5" : "8"}
                    fill="transparent"
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 1, opacity: 1 }}
                    transition={{ duration: 1.5, ease: "easeInOut" }}
                  />
                </motion.svg>
              </div>
            </div>
          </div>



          {/* Model */}
          <Typography
            style={{
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: matches ? "28px" : "80px",
              color: "#151617",
            }}
          >
            Model
          </Typography>
        </div>

        {/* Right Side */}
        <div style={{ width: matches ? "90%" : "600px" }}>
          <img
            src={oceanModel}
            alt="OCEAN Model"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: matches ? "16px" : "28px",
            lineHeight: matches ? "130%" : "100%",
            letterSpacing: "0px",
            textAlign: "center",
            padding: matches ? "8px" : "20px"
          }}
        >
          The OCEAN Model, also known as the Big Five Personality Traits, is a widely recognized
          framework for understanding human personality. It was developed through decades of
          psychological research, with significant contributions from Paul Costa and Robert McCrae in
          the 1980s. The model categorizes personality into five broad dimensions: Openness,
          Conscientiousness, Extraversion, Agreeableness, and Neuroticism (OCEAN).
        </Typography>
      </div>
      <div style={{ marginTop: matches ? '5%' : '3%', marginBottom: matches ? '5%' : '3%' }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: matches ? "36px" : "68px",
            lineHeight: "110%",
            letterSpacing: "0px",
            textAlign: "center",
          }}
        >
          Origin and Development
        </Typography>
      </div>

      <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: matches ? "18px" : "28px",
            lineHeight: matches ? "130%" : "110%",
            letterSpacing: "0px",
            textAlign: "center",
          }}
        >
          The foundation of the Big Five model can be traced back to the 1930s when Gordon Allport and Henry Odbert identified personality-related words in the English language. Later, in the 1940s, Raymond Cattell refined these into 16 key personality factors. However, it was in the 1980s that Paul Costa and Robert McCrae, along with Lewis Goldberg, refined the research into five key personality traits, formalizing the OCEAN model as we know it today.
        </Typography>
      </div>

      <div style={{ marginTop: matches ? "5%" : "3%", marginBottom: matches ? "5%" : "3%" }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: matches ? "18px" : "28px",
            lineHeight: "100%",
            letterSpacing: "0px",
            textAlign: "center",
            color: "#3479FF",
          }}
        >
          The Five Personality Traits of the OCEAN Model
        </Typography>
      </div>

      <div>
        <Grid container spacing={matches ? 2 : 4} justifyContent="center" padding={matches ? 2 : 3}>

          {/* First Row */}
          <Grid container item spacing={matches ? 2 : 4} justifyContent="center" xs={12} sm={12} md={10}>
            {[
              { id: 1, title: "Openness", text: "Reflects creativity, curiosity, and willingness to explore new experiences.", bg: "#E9D5FF" },
              { id: 2, title: "Conscientiousness", text: "Measures responsibility, organization, and dependability.", bg: "#D2A2F2" },
            ].map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Paper
                  elevation={2}
                  style={{
                    display: "flex",
                    backgroundColor: item.bg,
                    padding: matches ? "15px" : "20px",
                    borderRadius: "16px",
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-start",
                    minHeight: matches ? "auto" : "110px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: "42px",
                      height: "37px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {item.id}
                  </div>
                  <div>
                    <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                    <Typography>{item.text}</Typography>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* Second Row */}
          <Grid container item spacing={matches ? 2 : 4} justifyContent="center" xs={12} sm={12} md={10}>
            {[
              { id: 3, title: "Extraversion", text: "Assesses sociability, energy, and enthusiasm in social interactions.", bg: "#FFF07B" },
              { id: 4, title: "Agreeableness", text: "Evaluates kindness, empathy, and cooperation.", bg: "#6DBFF2" },
            ].map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Paper
                  elevation={2}
                  style={{
                    display: "flex",
                    backgroundColor: item.bg,
                    padding: matches ? "15px" : "20px",
                    borderRadius: "16px",
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-start",
                    minHeight: matches ? "auto" : "110px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: "42px",
                      height: "37px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {item.id}
                  </div>
                  <div>
                    <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                    <Typography>{item.text}</Typography>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* Last Single Row */}
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={2}
              style={{
                display: "flex",
                backgroundColor: "#E9D5FF",
                padding: matches ? "15px" : "20px",
                borderRadius: "16px",
                width: "100%",
                gap: "12px",
                alignItems: "flex-start",
                minHeight: matches ? "auto" : "110px",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: "42px",
                  height: "37px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                5
              </div>
              <div>
                <Typography style={{ fontWeight: "bold" }}>Neuroticism</Typography>
                <Typography>Measures emotional stability and resilience.</Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: matches ? "5%" : "3%", textAlign: "center" }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: matches ? "28px" : "50px",
            lineHeight: "110%",
            letterSpacing: "0px",
          }}
        >
          OCEAN Model in SkillSphere & Child Development
        </Typography>

        <div
          style={{
            width: matches ? "95%" : "80%",
            margin: "1.5% auto",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: matches ? "18px" : "30px",
              lineHeight: "130%",
            }}
          >
            The OCEAN Model helps assess children’s personality traits, guiding their learning, social skills, and career growth.
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            maxWidth: "100%",
            padding: matches ? 1 : 3,
            display: "flex",
            flexWrap: matches ? "wrap" : "nowrap",
            overflowX: matches ? "unset" : "auto"
          }}
        >

          <Grid item>
            <Card sx={{
              textAlign: 'center',
              backgroundColor: "#9B76D1",
              color: "white",
              borderRadius: 7,
              width: matches ? 180 : 225,
              height: matches ? 180 : 225,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                <Typography style={{ fontSize: matches ? '18px' : '22px', fontWeight: 400 }}>
                  Self-Awareness & Decision-Making
                </Typography>
                <Typography variant="body2">
                  Helps kids understand strengths, weaknesses, and choices.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{
              textAlign: 'center',
              backgroundColor: "#F2652A",
              color: "white",
              borderRadius: 7,
              width: matches ? 180 : 225,
              height: matches ? 180 : 225,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                <Typography style={{ fontSize: matches ? '18px' : '22px', fontWeight: 400 }}>
                  Learning & Adaptability
                </Typography>
                <Typography variant="body2">
                  Tailors teaching methods to personality types for better engagement.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{
              textAlign: 'center',
              backgroundColor: "#44AA8B",
              color: "white",
              borderRadius: 7,
              width: matches ? 180 : 225,
              height: matches ? 180 : 225,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                <Typography style={{ fontSize: matches ? '18px' : '22px', fontWeight: 400 }}>
                  Teamwork & Leadership
                </Typography>
                <Typography variant="body2">
                  Identifies leaders, team players, and conflict solvers.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{
              textAlign: 'center',
              backgroundColor: "#F5A751",
              color: "white",
              borderRadius: 7,
              width: matches ? 180 : 225,
              height: matches ? 180 : 225,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                <Typography style={{ fontSize: matches ? '18px' : '22px', fontWeight: 400 }}>
                  Career Exploration
                </Typography>
                <Typography variant="body2">
                  Aligns interests with future professions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </div>
      <div style={{ display: 'flex', marginTop: '5%', marginBottom: '5%' }} >
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <img src={Star} style={{ display: 'block', width: matches ? '30%' : '60%' }} />
        </div>

        <div style={{ width: '100%', display: 'flex' }} >
          <div
            style={{
              width: matches ? "80%" : "80%",
              paddingLeft: matches ? "0px" : "50px",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: matches ? "25px" : "32px",
                lineHeight: "120%",
                color: "#0C0B0B",
              }}
            >
              It enhances personal growth, communication, and success in education and life.
            </Typography>
          </div>
          <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
            <img src={orangestar} style={{ width: matches ? '50%' : '70%' }} />
          </div>
        </div>

      </div>



    </div>
  );
}
