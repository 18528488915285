import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsSection = ({ Data }) => {
  // Transform the data to match expected structure
  const testimonials = Data.map(item => ({
    name: item.name_of_person,
    handle: item.organization,
    review: item.review,
    rating: item.rating || 5 // Default to 5 if rating not provided
  }));

  console.log("Transformed testimonials:", testimonials);

  // Settings for the slider
  const settings = {
    infinite: true,
    arrows: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="py-16 w-screen h-[430px] text-center"
      style={{
        backgroundImage: "radial-gradient(circle at left, #087def 1%, transparent 20%)",
        backgroundColor: "white",
      }}
    >
      <motion.div
        whileHover={{ scale: 1.3 }}
        className="text-2xl lg:text-4xl mb-8 lg:mb-16 font-extrabold text-gray-900"
      >
        CUSTOMER<span className="text-orange-500 ml-3">TESTIMONIALS</span>
      </motion.div>

      {testimonials.length > 0 ? (
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="px-2">
              <TestimonialCard data={testimonial} />
            </div>
          ))}
        </Slider>
      ) : (
        <p className="text-gray-500">No testimonials available</p>
      )}
    </div>
  );
};

const TestimonialCard = ({ data }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
      }}
      transition={{ duration: 0.3 }}
      className="w-80 h-auto bg-white rounded-lg overflow-hidden shadow-md mx-auto"
    >
      <div className="p-6 bg-gray-100">
        <div className="flex justify-center">
          {Array(data.rating)
            .fill()
            .map((_, i) => (
              <span key={i} className="text-yellow-400 text-xl">
                ★
              </span>
            ))}
        </div>
        <p className="h-[13vh] flex items-center justify-center text-gray-600 text-sm">
          {data.review}
        </p>
      </div>

      <div className="bg-blue-500 h-[65px] justify-center text-white flex flex-col">
        <h4 className="font-bold text-xs uppercase tracking-wide text-center">
          {data.name}
        </h4>
        <p className="opacity-80 text-xs text-center">{data.handle}</p>
      </div>
    </motion.div>
  );
};

export default TestimonialsSection;