import { Card, CardContent, Typography, Grid } from "@mui/material";
import { motion, useInView } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Riasec from "../../../images/riasec.png"
import { useTypewriter } from "./Textanimation"
import orangeright from "../../../images/orange_star.png"
import Skybluestar from "../../../images/skybluestar.png"
import { useRef } from "react";
import halfgreen_Star from "../../../images/halfgreen_Star.png"
import { Paper } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

export default function Riaces_Model() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
                window.scrollTo(0, 0); // Scroll to the top of the page
              }, []);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
 

    
    const isInView1 = useInView(ref1, { once: true });
    const isInView2 = useInView(ref2, { once: true });
    
  
    const text1 = useTypewriter(
        "  The RIASEC Model, also known as the Holland Codes, is a career assessment framework that categorizes individuals into six personality types: Realistic, Investigative, Artistic, Social, Enterprising, and Conventional (RIASEC). It helps people understand how their personality traits align with different career paths.", 
        30,
        isInView1,
        200 
    );

    const text2 = useTypewriter(
        " Developed by Dr. John L. Holland in the 1950s, the model was first formalized in his 1959 publication. Over time, it became widely used in career counseling, education, and workforce development, helping individuals make informed career choices based on their personality traits.", 
        30,
        isInView2,
        300 
    );

    
    return (
        <div
            style={{
                height: "auto",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
                fontFamily: 'Nunito'
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    alignItems: "center",
                    justifyContent: 'center',
                    width: matches ? "95%" : "90%",
                    gap: matches ? "20px" : "80px",
                    margin: "0 auto",
                }}
            >
                {/* Left Side */}

                <div style={{ textAlign: matches ? "center" : "left" }}>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: matches ? "center" : "flex-start",
                        }}
                    >

                        <div>
                            <div>
                                <motion.div
                                    initial={{ opacity: 0, y: -20, scale: 0.8 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    transition={{ duration: 0.8, ease: "easeOut" }}
                                    whileHover={{ scale: 1.05 }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Nunito",
                                            fontWeight: 700,
                                            fontSize: matches ? "36px" : "100px",
                                            color: "#0072FF",
                                        }}
                                    >
                                        RIASEC
                                    </Typography>
                                </motion.div>
                            </div>
                            {/* Animated Curve */}
                            <div>
                                <motion.svg
                                    width={matches ? 140 : 400}
                                    height={matches ? 8 : 25}
                                    viewBox="0 0 480 30"
                                    strokeLinecap="round"
                                    style={{
                                        display: "block",
                                        marginTop: matches ? "-10px" : "-35px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                >
                                    <motion.path
                                        d="M10,15 Q275,-10 470,15"
                                        stroke="#E66A35"
                                        strokeWidth={matches ? "2.5" : "8"}
                                        fill="transparent"
                                        initial={{ pathLength: 0, opacity: 0 }}
                                        animate={{ pathLength: 1, opacity: 1 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}
                                    />
                                </motion.svg>
                            </div>
                        </div>
                    </div>



                    {/* Model */}
                    <Typography
                        style={{
                            fontFamily: "Nunito",
                            fontWeight: 700,
                            fontSize: matches ? "28px" : "80px",
                            color: "#151617",
                        }}
                    >
                    Model
                    </Typography>
                </div>

                {/* Right Side */}
                <div style={{ width: matches ? "90%" : "600px" }}>
                    <img
                        src={Riasec}
                        alt="OCEAN Model"
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                        }}
                    />
                </div>
            </div>
            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
                 
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                {/* First Paragraph */}
                <Typography
                    // ref={ref1}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                   The RIASEC Model, also known as the Holland Codes, is a career assessment framework that categorizes individuals into six personality types: Realistic, Investigative, Artistic, Social, Enterprising, and Conventional (RIASEC). It helps people understand how their personality traits align with different career paths.
                </Typography>
                </motion.div>
            </div>
            <div style={{ marginTop: matches ? '5%' : '3%', marginBottom: matches ? '5%' : '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "36px" : "68px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                    Origin and Development
                </Typography>
            </div>

            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                <Typography
                    // ref={ref2}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                   Developed by Dr. John L. Holland in the 1950s, the model was first formalized in his 1959 publication. Over time, it became widely used in career counseling, education, and workforce development, helping individuals make informed career choices based on their personality traits.
                </Typography>
                </motion.div>
            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", marginBottom: matches ? "5%" : "3%" }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "18px" : "32px",
                        lineHeight: "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        color: "#3479FF",
                    }}
                >
                   Key Components of the RIASEC Model 
                </Typography>
            </div>

            <div>
                <Grid container spacing={matches ? 2 : 4} justifyContent="center" padding={matches ? 2 : 3}>

                    {/* First Row */}
                    <Grid container item spacing={matches ? 2 : 5} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 1, title: "Realistic", text: "Hands-on, practical, and mechanical work (e.g., engineers, technicians, construction workers).  ", bg: "#E9D5FF" },
                            { id: 2, title: "Investigative", text: "Research-oriented, analytical, and problem-solving roles (e.g., scientists, data analysts, doctors).  ", bg: "#D2A2F2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Second Row */}
                    <Grid container item spacing={matches ? 2 : 4} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 3, title: "Artistic", text: "Creative, innovative, and expressive careers (e.g., designers, musicians, writers).  ", bg: "#FFF07B" },
                            { id: 4, title: "Social", text: "People-centered, helping, and teaching professions (e.g., teachers, counselors, social workers).", bg: "#6DBFF2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>

                            </Grid>

                            
                        ))}
                    </Grid>
                     {/* third Row */}
                     <Grid container item spacing={matches ? 2 : 4} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 5, title: "Enterprising", text: "Leadership, business, and persuasive roles (e.g., entrepreneurs, sales managers, executives).", bg: "#F48FB1" },
                            { id: 6, title: "Conventional", text: "Organized, detail-oriented, and structured careers (e.g., accountants, bankers, administrators). ", bg: "#E4FF8C" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                                
                            </Grid>

                            
                        ))}
                    </Grid>
                </Grid>

            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", }}>
                <div style={{ display: 'flex' }} >
                    

                    <div style={{  width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", padding: "20px 0" }}>
    <div
        style={{
            width: matches ? "100%" : "100%",
            paddingLeft: matches ? "0px" : "50px",
            textAlign: "center",
            display:'flex',
            justifyContent:'center',
        }}
    >
        <Typography
            sx={{
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: matches ? "36px" : "68px",
                lineHeight: "110%",
                letterSpacing: "0px",
                textAlign: "center",
                color: "#3479FF",
            }}
        >
            Where is it Used
        </Typography>
    </div>

    {/* Image container - pushes image to the right */}
    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <img 
            src={halfgreen_Star} 
            alt="Star Icon"
            style={{
                display: "block",
                width: "10%",
                position: "absolute",
            }} 
        />
    </div>
</div>

                </div>
                <div
                    style={{
                        justifyContent:'center',
                        width: matches ? "95%" : "70%",
                        margin: "1.5% auto",
                        textAlign: 'left',
                        
                    }}
                >
                    <ul
                        style={{
                            fontFamily: "Nunito",
                            fontSize: matches ? "14px" : "18px",
                            lineHeight: "150%",
                            paddingLeft: "20px",
                            marginTop: "15px",
                        }}
                    >
                        <li>
                            <strong>Career Counseling  :</strong> Helps students and professionals choose careers aligned with their personality. 
                        </li>
                        <li>
                            <strong>Education & Skill Development :</strong> Assists in designing personalized learning paths based on interests.
                        </li>
                        <li>
                            <strong>Workforce Development :</strong> Guides hiring managers in placing employees in roles where they excel.
                        </li>
                        <li>
                            <strong>Psychometric Assessments :</strong> Used in career guidance tests like Strong Interest Inventory (SII).
                        </li>
                    </ul>
                </div>

            </div>
            <div style={{ marginTop:  '3%', marginBottom:  '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "26px" : "53px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                  RIASEC Model in SkillSphere & Child Development 
                </Typography>
                <div style={{paddingLeft:'5%',width:'90%'}}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "23px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                   The RIASEC Model plays a crucial role in
                    SkillSphere assessments by helping children make informed career choices based on their strengths. 
                </Typography>
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        maxWidth: "100%",
                        padding: matches ? 1 : 3,
                        display: "flex",
                        flexWrap: matches ? "wrap" : "nowrap",
                        overflowX: matches ? "unset" : "auto"
                    }}
                >

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#9B76D1",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Career Exploration & Clarity 
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Guides career choices with structured assessments and early exploration. 4o
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F2652A",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Personalized Learning & Skill Development 
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Develops industry-relevant skills while fostering critical thinking, creativity, and analysis.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#44AA8B",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Informed Decision-Making 
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Aligns passions with careers while guiding academic choices for students.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F5A751",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '14px' : '18px', fontWeight: 400 }}>
                                Long-Term Career Success 
                                </Typography>
                                <Typography style={{ fontSize: matches ? '12px' : '16px',}}>
                                Boosts self-awareness, confidence, and career satisfaction while minimizing mismatches for long-term success.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    
    {/* Left Side Image */}
    <div style={{ flexShrink: 0 }}>
        <img src={Skybluestar} style={{ width: matches ? '50%' : '80%', display: 'block' }} />
    </div>

    {/* Text Section */}
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',textAlign: 'center' }}>
        <Typography
            sx={{
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: matches ? "20px" : "32px",
                lineHeight: "120%",
                color: "#0C0B0B",
                
            }}
        >
            It enhances career awareness, skill development, and informed decision-making for future success.
        </Typography>
    </div>

    {/* Right Side Image */}
    <div style={{ flexShrink: 0 }}>
        <img src={orangeright} style={{ width: matches ? '20%' : '60%', display: 'block' }} />
    </div>

</div>




        </div>
    );
}